import React from "react";
import Lottie from 'react-lottie';
import { connect } from "react-redux"

const Loader = ({ color, width = 160,data, height = 160, style, ...props }) => {

  const { isStopped, environment } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    
    animationData: data ?? (color ? color === "primary" ? require('assets/json/loader.json') :
      require('assets/json/loader-dark.json') : !environment.theme || environment.theme === "light" ? require('assets/json/loader.json') :
      require('assets/json/loader-dark.json')),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Lottie options={defaultOptions}
      height={width}
      width={height}
      style={{ marginTop: 0, ...style }}
      isStopped={isStopped} />
  );

}

export default connect(state => ({ environment: state.environment }))(Loader);