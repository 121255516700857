
const defaultState = {
 
 }
const snackbar = (state = defaultState , action) => {
  switch (action.type) {
    case 'SHOW_PROPERTIES_DRAWER':
      return {
        ...state,
        open: true,
        item: action.item,
        settings: action.settings
      }


    case 'HIDE_PROPERTIES_DRAWER':
      return {
        ...state,
        open:false,
        item: null,
        settings: null
        
      }


    default:
      return state
  }
  // return state

}

export default snackbar;