
const defaultState = { open: false, ids: [] }
const loader = (state = defaultState, action) => {
  switch (action.type) {
    case 'SHOW_LOADER':
      return {
        open: true,
        ids: [...state.ids, action.id]
      }


    case 'CLOSE_LOADER':
      return {
        ...state,
        open: false,
        ids: [...state.ids.filter(id => action.id !== id)]

      }


    default:
      return state
  }
  // return state

}

export default loader;