import BaseApi from "api/BaseApi";

class AuthApi extends BaseApi {  
  static get() {
    const api = new BaseApi();

    api.call(api.https().get(`api/auth`));

    return api;
  }

}

export default AuthApi;
