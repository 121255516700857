
const groupList = (state = [], action) => {
    switch (action.type) {
      case 'UPDATE_GROUP_LIST':
        return action.groupList
        
    
      default:
        return state
    }
    // return state
  
  }

  export default groupList;