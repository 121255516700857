
const supportedLanguages = ["en", "ms", "th", "id", "zh"]
const addLocalizeForAllLanguage=(addTranslationForLanguage, languages)=>{
    supportedLanguages.forEach(code=>{
        addTranslationForLanguage(languages, code)
    })

}

export {
    supportedLanguages,
    addLocalizeForAllLanguage
}