import "react"
import queryString from 'query-string';

const updateUrlSearchParams = (params, replace = true) => {

    const url = new URL(window.location);
    if (replace) {
        const currentParams = queryString.parse(window.location.search);
        Object.entries(currentParams).forEach(([key, value]) => {
            url.searchParams.delete(key);
        })
    }
    Object.entries(params).forEach(([key, value]) => {
        url.searchParams.set(key, value);
    })
    window.history.pushState({}, "", url);
}
export {
    updateUrlSearchParams
}