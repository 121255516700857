


class Localize{
    
    static add(props, name){
        // const en = require("assets/localization/"+name+"-en.json");
        // const ms = require("assets/localization/"+name+"-ms.json");
        // props.addTranslationForLanguage(en, "en");
        // props.addTranslationForLanguage(ms, "ms");
    }
    
    static addBase(props){
        Localize.add(props, "base");
        return Localize;
    }

    static addCommon(props){
        Localize.add(props, "common");
        return Localize;
    }
    
    
}

export default Localize;