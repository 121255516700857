import axios from 'axios';
import qs from "qs";
import BaseApi from "api/BaseApi"

class UserApi {

    static userSelf(body) {
        const api = new BaseApi();

        api.call(api.https().post(`/api/userSelf`, body));

        return api;
    }
    static sendPasswordResetLink(body) {
        const api = new BaseApi();
        api.call(api.https().post(`/api/SendPasswordResetLink/` + body));
        return api;
    }


    static resetPassword(body) {
        const api = new BaseApi();
        api.call(api.https().post(`/api/ResetPassword/`, body));
        return api;
    }

    static verifyEmail(body) {
        const api = new BaseApi();
        api.call(api.https().post(`/api/user/VerifyEmail/`, body));
        return api;
    }

    static resendVerifyEmail(email) {
        const api = new BaseApi();
        api.call(api.https().get(`/api/user/ResendVerifyEmail/${email}`));
        return api;
    }

    static signIn(UserName, Password, success, fail, error) {

        const body = {
            UserName,
            Password,
            grant_type: "password"
        }
        const api = new BaseApi();

        api.call(api.https().post('oauth/token', body))
            .then({
                complete: (response, e) => {
                    
                    if (e) {
                        if (!response) {
                            error(e)
                        }
                        else {
                            fail(response, e);
                        }
                    } else {
                        if (response.data.access_token) {
                            axios.defaults.headers.common['userKey'] = response.data.access_token;
                            if (success)
                                success(response);

                        }
                        else if (fail) {
                            fail(response);
                        }
                    }
                }
            });
    }


    static refresh(refresh_token, success, fail, error) {

        const body = {
            RefreshTokenId: refresh_token,
            // grant_type: "refresh_token"
        }
        axios.post('oauth/refreshtoken',
            // qs.stringify(body),
            body,
            {
                headers: {
                    // "Content-Type": "application/x-www-form-urlencoded",
                    'Access-Control-Allow-Origin': '*',

                },
                withCredentials: false,
            }
        ).then(function (response) {

            if (response.data.access_token) {
                axios.defaults.headers.common['userKey'] = response.data.access_token;
                if (success)
                    success(response);

            }
            else if (fail) {
                fail(response);
            }
        })
            .catch(function (e) {
                if (error)
                    error(e)
            });
    }

    static validate(refresh_token, success, fail, error) {

        const body = {
            refresh_token,
            grant_type: "refresh_token"
        }
        axios.post('oauth/token',
            qs.stringify(body),

            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    'Access-Control-Allow-Origin': '*',

                },
                withCredentials: false,
            }
        ).then(function (response) {

            if (response.data.access_token) {
                axios.defaults.headers.common['userKey'] = response.data.access_token;
                if (success)
                    success(response);

            }
            else if (fail) {
                fail(response);
            }
        })
            .catch(function (e) {
                if (error)
                    error(e)
            });
    }

    // getDetail(userid){
    //     axios
    //       .get(`https://svcdev.100doors.asia/api/`, { user })
    //       .then(res => {


    //       });
    // }
}

export default UserApi;
