const environment = (state = {}, action) => {
  switch (action.type) {
    case 'SIGN_IN': {
      const { type, ...rest } = action;
      if (rest?.accessToken === state?.accessToken)
        return state

      localStorage.setItem("accessToken", rest?.accessToken)
      return rest;
    }
    case 'SIGN_OUT': {
      const { type, ...rest } = action;
      delete rest.accessToken
      return {};
    }
    case 'UPDATE_FACEBOOK_INFO': {
      const { type, ...rest } = action;
      return { ...state, ...rest };
    }
    case 'UPDATE_TIKTOK_INFO': {
      const { type, ...rest } = action;
      return { ...state, ...rest };
    }
    case 'UPDATE_REV_INFO': {
      const { type, ...rest } = action;
      return { ...state, ...rest };
    }

    default:
      return state
  }
  // return state

}

export default environment;