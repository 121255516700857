const drawer = (state = (() => {
  return {
  }
})(), action) => {


  switch (action.type) {

    case "UPDATE_CHART_USAGE": {
      const { usage } = action;
      return {
        ...state,
        usage
      }
    }

    case "UPDATE_CREDIT_USAGE": {
      const { credit } = action;
      return {
        ...state,
        credit
      }
    }


    default:
      return state;
  }
  // return state

}

export default drawer;