import { useTheme } from "@material-ui/core"
import React from "react"

function Maintenance() {
    const theme = useTheme();
    return (
        <div style={{ background: theme.palette.primary.main, width: "100vw", minHeight: "100vh" }}>
            <iframe title="vmap"
                src={"https://www.dattel.asia/voyager-upgrading/"}
                style={{
                    width: `100vw`,
                    height: "100vh",
                    border: "none",
                    transformOrigin: "top",
                }} />
        </div>
    )

}

export default Maintenance;