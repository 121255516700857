import React from "react";
import { Typography, Button } from "@material-ui/core";
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {


        return { hasError: true };
    }
    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        // window.location.reload();
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div style={{
                width: "100vw",
                minHeight: "70vh",
                display: "flex"
            }}>
                <div style={{ margin: "auto", textAlign: "center" }}>
                    <Typography variant="subtitle2">
                        Something went wrong. Please refresh this page.
            </Typography>
            <br/>
            <br/>
                    <Button variant="contained" color="primary" onClick={()=>window.location.reload()}>Refresh</Button>
                </div>


            </div>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;