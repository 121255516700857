import axios, { CancelToken } from 'axios'
import { throttleAdapterEnhancer, cacheAdapterEnhancer } from 'axios-extensions';
import { store } from '../Main';

let cancel;

export function getCancelToken() { return cancel };
function config() {
    let baseUrl;
    if (window.baseUrl != null) {
        baseUrl = window.baseUrl;
    } else {
        if (window.location.port === "9001") {
            baseUrl = "http://localhost:5000";
        }
        else {
            baseUrl = "https://dev-coreangelfish.in.dattel.asia";
        }
    }
    axios.defaults.baseURL = baseUrl;
    axios.interceptors.request.use(function (config) {

        const { user, userProfile = {} } = store.getState();
        const { body = {} } = userProfile;
        config.headers = {
            'Cache-Control': 'no-cache',
            'Access-Control-Allow-Origin': '*',
            'Authorization': "bearer " + user.accessToken,
            'Id': user.Id || body.Id,
            'Accept-Language': localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
            'Content-Type': 'application/json',
        }
        config.adapter = throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter));
        config.cancelToken = new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        })

        return config;
    }, function (error) {
        return Promise.reject(error);
    });
    //axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
    axios.defaults.headers.common['apiKey'] = 'SuFH7x5V2v';
    axios.defaults.adapter = throttleAdapterEnhancer(axios.defaults.adapter, 2 * 1000)

}

export default config;