import { MuiThemeProvider } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import "assets/css/material-dashboard-react.css"

import Loader from "components/Loader/Loader"
import localize from "localization/localize"
import React, { useLayoutEffect, useRef } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import { LocalizeProvider, withLocalize } from "react-localize-redux"
import { connect } from "react-redux"
import { BrowserRouter, Switch } from "react-router-dom"

import { CssBaseline } from "@material-ui/core"
import PrivateRoute, { signout } from "routes/PrivateRoute.jsx"
import PublicRoute from "routes/PublicRoute.jsx"
import Redirect from "routes/Redirect.jsx"
import {
  primaryColor,
  revPrimaryColor,
  setPrimaryColor,
  setRevPrimaryColor,
  setUseLogo,
} from "./assets/jss/material-dashboard-react"
import createTheme from "./assets/themes/default"
import NewVersionDialog from "./components/Dialog/NewVersionDialog"
import ErrorBoundary from "./components/Error/ErrorBoundary"
import SnackbarConnect from "./components/Snackbar/SnackbarConnect"
import "./global-function"

import { signIn, signInCopyToken, signOut } from "actions"
import config from "api/config"
import axios from "axios"
import { createBrowserHistory } from "history"
import { useEffect, useState } from "react"
import {
  decryptToken,
  isIframeSSOEnable,
  isLandingUseRevTheme,
  setIsLandingUseRevTheme,
  setMainPath,
  setRevUrl,
  setTrialCode,
  setVoyagerUrl,
} from "utils/config"
import zoid from "zoid"
import MaintenanceApi from "./api/Maintenance"
import InstallAppBottomSheet from "./components/BottomSheet/InstallAppBottomSheet"
import Maintenance from "./layouts/Maintenance/Maintenance"
import { updateUrlSearchParams } from "utils/url"
import { createZoidSignOut } from "components/zoid/auth"
import { createZoidSignInButton } from "components/zoid/Button"
import UserApi from "api/User"
import AuthApi from "api/AuthApi"

import { supportedLanguages } from "utils/localize"

config()

const history = createBrowserHistory()

class Router extends BrowserRouter {
  history
}

const SignInButton = connect(() => ({}))((props) => {
  const callLogin = (loginEmail, loginPass) => {
    if (loginEmail.length > 0 && loginPass.length > 0) {
      UserApi.signIn(
        loginEmail,
        loginPass,
        async (response) => {
          props.dispatch(signIn(response?.data || {}))
          await window.xprops.onSuccess(window, response)
        },
        async (e) => {
          await window.xprops.onFailed(window, e)
        },
        async (e) => {
          await window.xprops.onError(window, e)
        }
      )
    } else {
      window.xprops.onFailed(window)
    }
  }
  window.xprops.setLoginMethod(callLogin)

  useEffect(() => {
    window.xprops.getAuth().then(
      ([loginEmail, loginPass]) => {
        callLogin(loginEmail, loginPass)
      },
      () => {
        window.close()
      }
    )
    // eslint-disable-next-line
  }, [])
  return <ThemeWrapper config={config}>requesting....</ThemeWrapper>
})

const Oauth = connect(({ user }) => ({ user }))(
  ({ user, config, dispatch }) => {
    const sendAuth = (user) => {
      window.xprops.onAuthChange({ ...user, origin: window.location.host })
    }
    const { accessToken } = user ?? {}
    useEffect(() => {
      if (accessToken) {
        const token = user.refreshToken
        AuthApi.get().then({
          complete: (response, error) => {
            if (error)
              if (error?.response && error?.response.status === 401) {
                if (token) {
                  UserApi.refresh(
                    token,
                    (response) => {
                      dispatch(
                        signIn(
                          response.data.access_token,
                          response.data.refresh_token
                        )
                      )
                    },
                    () => {
                      // signout(props);
                    },
                    (error) => {
                      if (error.response && error.response.status === 401)
                        signout(dispatch)
                    }
                  )
                } else signout(dispatch)
                return
              }
          },
        })
      }
      sendAuth(user)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken])

    return ""
  }
)

export const ThemeContainer = ({ theme, children, config, ...props }) => {
  if (!theme) {
    theme = createTheme("light", config?.primary)
  }

  return (
    <MuiThemeProvider theme={theme} {...props}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

export const ThemeWrapper = connect((state) => ({
  environment: state.environment,
}))(
  ({
    hasThemeSelection,
    environment,
    dispatch,
    children,
    state,
    config,
    ...props
  }) => {
    // const theme = hasThemeSelection ? getTheme(environment.theme) : lightTheme
    const _theme = createTheme(
      !hasThemeSelection ? "light" : environment.theme,
      config?.primary
    )

    return (
      <ThemeContainer theme={_theme} {...props} config={config}>
        {children}
        <SnackbarConnect />
        <InstallAppBottomSheet />
      </ThemeContainer>
    )
  }
)

const initializeLocale = async (props) => {
  const onMissingTranslation = ({ translationId, languageCode }) => {
    return translationId
  }
  props.initialize({
    languages: [
      { name: "appearance.english", code: "en" },
      // { name: "appearance.indonesian", code: "id" },
      { name: "appearance.malay", code: "ms" },
      // { name: "appearance.thai", code: "th" },
    ],
    translation: {},

    options: {
      renderToStaticMarkup,
      onMissingTranslation,
    },
  })

  await ["intl_voyager_", "intl_rev_"].forEachAwait(async (filename) => {
    await supportedLanguages.forEachAwait(async (locale) => {
      const json = await import(`assets/localization/${filename}${locale}.json`)
      props.addTranslationForLanguage(json, locale)
    })
  })

  localize.addCommon(props)
}
function Routers({ config, ...props }) {
  useLayoutEffect(() => {
    initializeLocale(props)
  })
  const [maintenanceFlag, setMaintenanceFlag] = useState()
  useLayoutEffect(() => {
    MaintenanceApi.get().then({
      complete: (res) => {
        setMaintenanceFlag(res.data && res.data.MaintenanceFlag)
      },
    })
  }, [])
  let hasNewVersion = false

  if (window.require) {
    const electron = window.require("electron")

    const { app } = electron.remote
    hasNewVersion = app.getVersion() !== window.latestVersion
  }
  const _primaryColor = isLandingUseRevTheme ? revPrimaryColor : primaryColor

  const [indexRoutes, setIndexRoutes] = useState()
  if (window.location.pathname === "/signInButton") {
    return <SignInButton />
  }

  import("routes/index.jsx").then((e) => {
    setIndexRoutes(e.default)
  })

  if (!indexRoutes) return ""
  return maintenanceFlag == null ? (
    ""
  ) : maintenanceFlag ? (
    <Maintenance />
  ) : (
    <Router basename={"/"}>
      <React.Suspense
        fallback={
          <div
            style={{
              width: "100vw",
              height: "100vh",
              textAlign: "center",
              verticalAlign: "center",
              paddingTop: "40vh",
              backgroundColor: _primaryColor,
            }}
          >
            <Loader />
          </div>
        }
      >
        <Switch>
          {indexRoutes.map((prop, key) => {
            const LoadableComponent =
              typeof prop.component === "function"
                ? React.lazy(prop.component)
                : prop.component

            const hasThemeSelection =
              prop.path.includes("/u") || prop.path.includes("/rev")
            const component = (props) => {
              const showDialog = hasNewVersion && !window.newVersionClose

              return (
                <ThemeWrapper
                  config={config}
                  hasThemeSelection={hasThemeSelection}
                >
                  <LoadableComponent {...props} />
                  {showDialog ? <NewVersionDialog /> : ""}
                </ThemeWrapper>
              )
            }

            if (prop.redirect)
              return <Redirect from={prop.path} to={prop.to} key={key} />
            else if (prop.protected)
              return (
                <PrivateRoute
                  exact={prop.exact}
                  path={prop.path}
                  component={component}
                  key={key}
                />
              )
            else
              return (
                <PublicRoute
                  exact={prop.exact}
                  path={prop.path}
                  component={component}
                  key={key}
                />
              )
          })}
        </Switch>
      </React.Suspense>
    </Router>
  )
}

const RoutersLocalize = withLocalize(
  React.memo(
    (props) => <Routers {...props} />,
    () => true
  )
)

const App = (props) => {
  const host = window.location.host
  const origin = window.location.origin

  const [config, setConfig] = useState()
  // const configPath = `${origin}/config/${host}.json`;
  let configPath = `${origin}/config/mcil-ads.dev.dattel.asia.json`
  if (!origin.includes("localhost")) {
    configPath = `${origin}/config/${host}.json`
  }

  useEffect(() => {
    if (!config)
      axios.get(configPath).then(
        (response) => {
          setConfig(response?.data)
        },
        () => {
          setConfig({})
        }
      )
    // eslint-disable-next-line
  }, [])

  const authRef = useRef()
  const search = window.location.search
  const params = search.length ? search.substring(1).split("&") : []
  const map = {}
  params.forEach((param) => {
    const index = param.indexOf("=")
    if (index > 0) map[param.substring(0, index)] = param.substring(index + 1)
  })
  const { auth } = map
  useEffect(() => {
    if (auth) {
      const data = decryptToken(auth)

      if (data?.accessToken) props.dispatch(signInCopyToken(data))
      delete map.auth
      updateUrlSearchParams(map)
    }
    // eslint-disable-next-line
  }, [auth])

  if (!config) return ""

  setMainPath(config?.mainPath)
  setPrimaryColor(config?.primaryColor)
  setRevPrimaryColor(config?.revPrimaryColor)
  // setLogo(config?.logo, config?.logoWidth, config?.logoHeight)

  setUseLogo(config?.voyagerLogo)
  setIsLandingUseRevTheme(config?.landingTheme)

  setTrialCode(config?.trialCode)
  if (config?.voyagerUrl && !config?.voyagerUrl?.includes(origin))
    setVoyagerUrl(config?.voyagerUrl)
  else if (!config?.revUrl?.includes(origin)) setRevUrl(config?.revUrl)

  const isAuthPath = window.location.pathname === "/oauth"
  const isButtonPath = window.location.pathname === "/signInButton"
  const url = config?.voyagerUrl ?? config?.revUrl
  var OauthComponent = zoid.create({
    tag: "oauth-component",
    url: `${isAuthPath ? window.location.origin : url}/oauth`,
  })

  createZoidSignInButton(
    isButtonPath ? window.location.origin : config?.voyagerUrl ?? config?.revUrl
  )
  createZoidSignOut(
    isButtonPath ? window.location.origin : config?.voyagerUrl ?? config?.revUrl
  )

  if (isAuthPath) return <Oauth config={config} />
  else if (url && isIframeSSOEnable()) {
    OauthComponent({
      onAuthChange: function (auth) {
        if (
          auth?.accessToken !== authRef.current?.accessToken &&
          auth?.origin !== host
        ) {
          if (auth?.accessToken) props.dispatch(signInCopyToken(auth))
          else props.dispatch(signOut())

          authRef.current = auth
        }
      },
    }).render("#auth-root")
  }

  return (
    <LocalizeProvider>
      {/* <MuiThemeProvider theme={theme}> */}
      <ErrorBoundary>
        <RoutersLocalize config={config} />
      </ErrorBoundary>

      {/* </MuiThemeProvider> */}
    </LocalizeProvider>
  )
}

export default connect((state) => ({}))(App)

export { history }
