import { showSnackbar } from "../actions";
import MyProfileApi from "../api/MyProfileApi";
import UserProfileApi from "../api/UserProfileApi";
import { Translate } from "react-localize-redux";
import React from "react";

// UserProfile: Start
export const updateUserProfile = profile => ({
    type: "UPDATE_USER_PROFILE",
    profile
})

export const clearUpdate = () => ({
    type: "CLEAR_UPDATE"
})

export const reduxNewUser = newUser => ({
    type: "NEW_USER",
    newUser
})

export const reduxIsUpdate = isUpdate => ({
    type: "PROFILE_UPDATE",
    isUpdate
})

export const reduxIsCreate = isCreate => ({
    type: "CREATE",
    isCreate
})

export const isAdmin = () => ({
    type: "ADMIN",
})

export const notAdmin = () => ({
    type: "NOT_ADMIN",
})

export const makeDirty = isDirty => ({
    type: "DIRTY",
    isDirty
})

export const cleanDirty = isDirty => ({
    type: "CLEAN_DIRTY",
    isDirty
})

export const makePristine = () => ({
    type: "PRISTINE",
})

export const makeValid = invalid => ({
    type: "VALID",
    invalid
})

export const makeInvalid = invalid => ({
    type: "INVALID",
    invalid
})
// UserProfile : End

export function updateMyProfile(body, state) {
    return (dispatch) => {
        MyProfileApi.update(body).then({
            complete: (response, error) => {
                if (error) {
                    dispatch(clearUpdate());
                    dispatch(showSnackbar(<Translate id="snackbar.error_updateUser" />, "error"));
                    return;
                }
                dispatch(getMyProfile({ body }));
                // dispatch(updateUserProfile({ body }));
                dispatch(cleanDirty(state));
                dispatch(showSnackbar(<Translate id="snackbar.success_update" />, "success"));
            }
        });
    }

}



export function createUser(body) {
    return (dispatch) => {
        UserProfileApi.create(body).then({
            complete: (response, error) => {
                if (error) {
                    dispatch(showSnackbar(<Translate id="snackbar.error_registerUser" />, "error"));
                    return;
                }
                dispatch(showSnackbar(<Translate id="snackbar.success_register" />, "success"));
            }
        });
    }
}

export function getMyProfile() {
    return (dispatch, getState) => {
        const { user } = getState()
        if (user && user.accessToken)
            MyProfileApi.get().then({
                complete: (response, error) => {
                    if (error || !response) {
                        // dispatch(showSnackbar("Unable to retrieve user data.", "error"));
                        return;
                    }
                    const body = {
                        ...response.data
                    }
                    dispatch(updateUserProfile({ body }));
                    // dispatch(getUser(body.Id));

                }
            });
    }
}


export function getUser(id) {
    return (dispatch) => {
        UserProfileApi.get(id).then({
            complete: (response, error) => {
                if (error || !response) {
                    // dispatch(showSnackbar("Unable to retrieve user data.", "error"));
                    return;
                }
                const body = {
                    ...response.data
                }
                dispatch(updateUserProfile({ body }));
            }
        });
    }
}

export function amISuperAdmin() {
    return (dispatch) => {
        MyProfileApi.amIAdmin().then({
            complete: (response, error) => {
                if (error) {
                    dispatch(notAdmin());
                    return;
                }
                if (response.data === 'Not Admin')
                    dispatch(notAdmin());
                else
                    dispatch(isAdmin());
            }
        });
    }
}
