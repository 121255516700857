import zoid from "zoid";

let ZoidSignInButton;
const createZoidSignInButton = (url) => {
    ZoidSignInButton = zoid.create({
        tag: 'sign-button-component',
        url: `${url}/signInButton`,
        defaultContext: "popup",
        // autoResize: {
        //     width: true,
        //     height: true,
        // },
        dimensions: {
            width: "100%",
            height: "40px",
        },
    });
}
export { createZoidSignInButton, ZoidSignInButton };

