import BaseApi from "api/BaseApi"

class MyProfileApi extends BaseApi {
    static path = "api/myprofile";

    static get() {

        const api = new BaseApi();

        api.call(api.https().get(`${MyProfileApi.path}`));

        return api;
    }
    
    static update(body) {
        
        const api = new BaseApi();
        
        api.call(api.https().put(`${MyProfileApi.path}`, body));
        
        return api;
    }
    
    static getCompany() {

        const api = new BaseApi();

        api.call(api.https().get(`api/mycompany`));

        return api;
    }
    
    static amIAdmin() {

        const api = new BaseApi();

        api.call(api.https().get(`api/user/amiadmin`));

        return api;
    }
}

export default MyProfileApi;
