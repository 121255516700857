const defaultState = {
    admin: false,
    isUpdate: '',
    isDirty: [],
    isCreate: false,
    invalid: [],
    body: {
        // name:'Dattel Sdn. Bhd.',
        // id:'1061086-X',
        // businessRegion:'Malaysia',
        // organizationSize:'D',
        // contactNumber:'+60379311006',
        // website:'https://www.dattel.asia',
        // address:'Unit 5-9-5, Block 5, VSQ @ PJ City Centre, Jalan Utara',
        // postcode:'46200',
        // cityState:'20',
        // industry:'83',
        // sector:'0',
        // subSector:'0',
        // businessNature:'0',
        // companyCapital:'0',
        // revenue:'0',
    },
    users: [
        {
            id: '10',
            email: 'ammy@dattel.asia'
        },
        {
            id: '11',
            email: 'nurliyana@dattel.asia'
        },
    ],
    companies: [

    ],
    viewingLists: {

    },
    currentCompany: {

    }
}

const company = (state = defaultState, action) => {
    switch (action.type) {
        case 'UPDATE_USER_COMPANY':
            return {
                ...state,
                ...action.company,
                isUpdate: ''
            }
        case 'UPDATE_COMPANIES':
            return {
                ...state,
                companies: action.companies
            }
        case 'CURRENT_COMPANY':
            return {
                ...state,
                currentCompany: action.currentCompany
            }
        case 'ADD_ORGANIZATION':
            return {
                ...state,
                companies: [...state.companies, action.companies]
            }
        case 'ORGANIZATION_UPDATE':
            return {
                ...state,
                isUpdate: action.isUpdate
            }
        case 'CLEAR_UPDATE':
            return {
                ...state,
                isUpdate: ''
            }
        case 'DIRTY':
            return {
                ...state,
                isDirty: state.isDirty.includes(action.isDirty) ? state.isDirty : [...state.isDirty, action.isDirty]
            }
        case 'CLEAN_DIRTY':
            return {
                ...state,
                isDirty: [...state.isDirty.filter(e => e !== action.isDirty)]
            }
        case 'PRISTINE':
            return {
                ...state,
                isDirty: []
            }
        case 'INVALID':
            return {
                ...state,
                invalid: state.invalid.includes(action.invalid) ? state.invalid : [...state.invalid, action.invalid]
            }
        case 'VALID':
            return {
                ...state,
                invalid: [...state.invalid.filter(e => e !== action.invalid)]
            }
        default:
            return state
    }
}

export default company;