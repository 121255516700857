const drawer = (state = (() => {
  return {
  }
})(), action) => {


  switch (action.type) {

    case "UPDATE_FAVOURITE_FEATUREDATASET": {
      const { favourites } = action;
      return {
        ...state,
        favourites
      }
    }


    default:
      return state;
  }
  // return state

}

export default drawer;