import React from "react";
import queryString from 'query-string'
import {
    Redirect,
} from "react-router-dom";
import { encryptToken, revUrl, voyagerUrl } from "utils/config";

export function windowSearchToObject() {
    return queryString.parse(window.location.search);

}
export function redirectQuery(props, other) {


    if (props.location.search) {
        const values = queryString.parse(props.location.search);
        const redirect = values.redirect


        if (redirect && redirect.length) {
            if (redirect.startsWith("demo") ||
                redirect.startsWith("/demo"))
                return null;

            const text = redirect.split("?");
            return <Redirect
                to={{
                    pathname: text[0],
                    search: text[1] ? `?${(text[1])}` : "",
                    // pathname: redirect.startsWith("demo") ? redirect.substring(4) :
                    // redirect.startsWith("/demo") ? redirect.substring(5) : redirect,
                    state: { from: props.location }
                }}
            />
        }

        return null;
    }
}

export function isBlacklistRoute(routes = [], route) {
    return routes.includes(route);
}

export function isDisabledRoute(routes = [], route) {
    return routes.find(r => `${route}/`.includes(`${r}/`));
}

export function redirectWithAuth(path, data) {
    const token = encryptToken(data).toString()
    const url = `${voyagerUrl ?? revUrl ?? ""}${path}?auth=${token}`
    return url;
}