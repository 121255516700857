import React from "react";
import { Helmet } from "react-helmet";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { isIframeSSOEnable, isLandingUseRevTheme, revUrl, voyagerUrl } from "utils/config";
import { signIn, signOut } from "../actions";
import { getMyProfile } from "../actions/profile";
import AuthApi from "../api/AuthApi";
import User from "../api/User";
import { redirectQuery, redirectWithAuth, windowSearchToObject } from "./utils";
import { ZoidSignOutComponent } from "components/zoid/auth"


function signout(dispatch, props, millis = 500) {
  if (!isIframeSSOEnable()) {
    ZoidSignOutComponent({
      onSignOut: () => {

      }
    }).render('#signout-root');
  }
  dispatch(signOut());
  
  if (window.xprops) {
    window.close()
  }
}
const PrivateRoute = ({
  render,
  component: Component, dispatch,
  accessToken, refreshToken,
  expired,
  translate,
  componentProps = {},
  data,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {


      const redirect = redirectQuery(props);
      if (redirect)
        return redirect;
      const userKey = accessToken;
      let auth = Boolean(userKey);
      let isSignOut = rest.path.includes("/signout");
      let location = props.location;
      if (isSignOut) {
        const { from } = windowSearchToObject();
        signout(dispatch, props, 100);
        location = from === "rev" ? { pathname: "/rev" } : undefined;
        auth = false;

      }

      if (auth) {
        const token = refreshToken;
        expired = true;
        AuthApi.get().then({
          error: (error) => {
            if (error.response && error.response.status === 401) {
              if (token) {
                User.refresh(token, (response) => {
                  dispatch(signIn(response.data.access_token, response.data.refresh_token));
                  dispatch(getMyProfile());
                }, () => {
                  // signout(props);
                }, (error) => {
                  if (error.response && error.response.status === 401)
                    signout(dispatch, props);
                });
              }
              else
                signout(dispatch, props);


            }
          }
        });

      }

      if (voyagerUrl && rest?.path?.startsWith("/u")) {
        window.location.href = redirectWithAuth(rest.location.pathname, data)
        return <div />
      }

      if (revUrl && rest?.path?.startsWith("/rev")) {
        window.location.href = redirectWithAuth(rest.location.pathname, data)
        return <div />
      }

      return auth ? (
        <React.Fragment>
          <Helmet>
            {rest.title ? <title>{translate(rest.title)}</title> : <title>{isLandingUseRevTheme ? "REV" : "Voyager"}</title>}
            {rest.description ? <meta name="description" content={rest.description} /> : ""}
          </Helmet>
          <Component {...props} {...componentProps} />
        </React.Fragment>

      ) : (
        <Redirect
          to={{
            // pathname: isSignOut ? "/" : `/signin/?to=${props.location.pathname}`,
            pathname: "/",
            // pathname: expired ? "/error/401" : `/signin/?to=${props.location.pathname}`,
            state: { from: location }
          }}
        />
      )
    }
    }
  />
);

export default connect(({ user }) => ({ ...user, data: user }))(withLocalize(PrivateRoute));
export { signout };


