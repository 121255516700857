// eslint-disable-next-line
import React from "react";
import ReactDOM from "react-dom";
import Main, { store } from "./Main";
import registerServiceWorker from "./registerServiceWorker";
import TagManager from "react-gtm-module";
// ReactGA.initialize('G-37QDBLYZVT');

const forEachAwait = async function (callback) {
  for (let index = 0; index < this.length; index++) {
    const element = this[index];
    await callback(element, index)

  }
}

const mapAwait = async function (callback) {
  const list = []
  for (let index = 0; index < this.length; index++) {
    const element = this[index];
    list[index] = await callback(element, index)
  }
  return list
}
// eslint-disable-next-line no-extend-native
Array.prototype.forEachAwait = forEachAwait
// eslint-disable-next-line no-extend-native
Array.prototype.mapAwait = mapAwait

const tagManagerArgs = {
  gtmId: 'GTM-5JLBTMP',
}


TagManager.initialize(tagManagerArgs)
ReactDOM.render(
  <Main />,
  document.getElementById("root"));


registerServiceWorker(store);

