
function addTab(state, { type, ...rest }, index) {
  const tab = {
    reports: [],
    createdDate: new Date().getTime(),
    ...rest,
  };

  state.tabs[index] = (tab)
  return tab;
}

const environment = (state = (() => {

  return {
    reports: [],
    model: {},
    tabs: {},
    focusTab: 0,
    RowHeadersMap: {},
    ColumnHeadersMap: {},
  }
})(), action) => {

  const type = action.type.replace("_V2", "");
  if (!action.type.endsWith("_V2"))
    return state;

  // if (!state.focusTab) {
  //   state.focusTab = Object.keys(state.tabs)[0];
  // }


  const { focusTab, tabs } = state;

  const getCurrentTab = () => {
    return tabs[focusTab];
  }

  switch (type) {
    case 'UPDATE_SCROLL_INDEX':
      return {
        ...state,
        scrollIndex: action.scrollIndex,
      }
    case 'STORE_MODEL':
      return {
        ...state,
        model: action.model,
      }
    case 'GENERATE_WORKSPACE': {
      const focusTab = state.focusTab || 0;
      const reports = state.tabs[focusTab].reports || [];
      let newState = action.model ? [
        ...reports.filter(c => c.referId !== action.model[0].replaceId),
        ...action.model,

      ] : [
          ...reports
        ];

      const tab = getCurrentTab();
      const { fields = {} } = tab;
      tab.reports = newState;
      tab.fields = {
        ...fields,
        collapse: 2
      }

      return {
        ...state,
        tabs: { ...state.tabs },
      }
    }
    case 'UPDATE_WORKSPACE': {
      let { tabs, focusTab } = state;
      const { id, title } = action;

      const report = tabs[focusTab].reports.find(report => report.id === id);

      if (!report)
        return state;

      report.Title = title;
      return {
        ...state,
        focusTab
      }
    }
    case "REMOVE_WORKSPACE":
      const { referId } = action.model;
      getCurrentTab().reports.filter(c => c.referId !== referId);
      return {
        ...state,
      }
    case 'ADD_TAB':

      let _index = 0;
      while (state.tabs[_index]) {
        _index++;
      }
      if (!action.title)
        action.title = `Tab ${Math.abs(_index + 1)}`;

      addTab(state, action, _index);


      return {
        ...state,
        focusTab: _index,
        tabs: state.tabs,
      }
    case 'CHANGE_TAB':
      // if (!state.tabs[action.tabIndex]) {
      //   action.title = `${action.tabIndex < 0 ? ("Report " + Math.abs(action.tabIndex)) : ("Criteria " + (action.tabIndex + 1))}`;
      //   addTab(state, action, action.tabIndex);
      // }
      return {
        ...state,
        focusTab: action.tabIndex,
      }

    case 'UPDATE_TAB':

      let { tabIndex = focusTab } = action;


      if (tabIndex === undefined) return state;


      if (!tabs[tabIndex]) {
        tabs[tabIndex] = addTab(state, action, tabIndex);
      }

      const { title, ...fields } = action.props || {};
      if (title)
        tabs[tabIndex].title = title;
      tabs[tabIndex].fields = {
        ...tabs[tabIndex].fields,
        ...fields,
      }
      return {
        ...state,
        tabs
      }

    case 'DELETE_TAB': {
      let { tabs } = state;

      const keys = Object.keys(tabs).sort((key1, key2) => {
        const a = tabs[key1];
        const b = tabs[key2];
        return a && b ? (a.createdDate - b.createdDate) : 0;
      })
        .filter(key => key >= 0);
      _index = keys.indexOf(action.tabIndex);
      (delete tabs[action.tabIndex]);

      if (focusTab === _index) {


        _index = keys[_index > 0 ? _index - 1 : _index + 1];
      } else { 
        _index = focusTab;
      }

      if (isNaN(_index) || _index < 0) {
        _index = 0;
        addTab(state, {
          title: "Tab 1",
        }, 0);
      }

      const _newState = {
        ...state,
        focusTab: _index,
        tabs: { ...tabs },

      };
      return _newState
    }
    case 'ADD_ALL_REPORTS': {
      // tabs
      if (action.reports) {
        const tab = addTab(state, action, -action.reports[0].ReportId);
        tab.reports = action.reports;
        tab.title = "Report " + action.reports[0].ReportId;

        return {
          ...state,
          focusTab: _index,
          tabs: state.tabs,
        }
      }
      else
        return state;
    }
    case 'DELETE_REPORT':


      getCurrentTab().reports = getCurrentTab().reports.filter(c => c.id !== action.id);
      // getCurrentTab().reportCount = newState.length;
      return {
        ...state,
        tabs: { ...state.tabs },
      }
    case 'DELETE_ALL_REPORTS':
      getCurrentTab();
      return {
        ...state,
        reports: []
      }
    case 'DELETE_REPORTS':
      const index = state.reports.indexOf(action.report);

      if (index >= 0)
        state.reports.splice(index, 1);
      return {
        ...state,
        reports: [
          ...state.reports
        ]
      }

    case 'CLEAR_CREATE_WORKSPACE':
      getCurrentTab().reports = [];
      return {
        ...state,
      }


    case 'UPDATE_CRITERIA_INFO':
      const {
        query,
        RowHeaders,
        ColumnHeaders,
        Quarters,
      } = action;

      const { info = {} } = state;
      const {
        RowHeadersMap = {},
        ColumnHeadersMap = {},
        QuartersMap = {}
      } = info;

      RowHeadersMap[query] = RowHeaders;
      ColumnHeadersMap[query] = ColumnHeaders;
      QuartersMap[query] = Quarters;
      return {
        ...state,
        info: {
          RowHeaders,
          ColumnHeaders,
          RowHeadersMap,
          ColumnHeadersMap,
          QuartersMap,
        }

      }
    default:
      return state;
  }
  // return state

}

export default environment;