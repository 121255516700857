const drawer = (state = (() => {
  return {
    drawers:[]
  }
})(), action) => {


  switch (action.type) {

    case "UPDATE_DRAWERS": {
      const { drawers } = action;
      return {
        ...state,
        drawers
      }
    }


    default:
      return state;
  }
  // return state

}

export default drawer;