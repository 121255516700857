// eslint-disable-next-line no-unused-vars
function addTab(state, { type, ...rest }, index) {
  const tab = {
    reports: [],
    createdDate: new Date().getTime(),
    ...rest,
  };

  state.tabs[index] = (tab)
  return tab;
}
const environment = (state = (() => {
  return {

  }
})(), action) => {


  switch (action.type) {

    case "UPDATE_NAVIGATE_FILTERS": {
      const { filters } = action;
      return {
        ...state,
        filters
      }
    }

    case "UPDATE_NAVIGATE_IDENTITY": {
      const { identity } = action;
      return {
        ...state,
        identity
      }
    }

    default:
      return state;
  }
  // return state

}

export default environment;