import React, { useState } from "react";
import { Paper, Typography, Button, Slide } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux"
import { setLastCancelInstallDate } from "../../actions";

const InstallAppBottomSheet = ({ dispatch, date = 0 }) => {

    const timeGap = new Date().getTime() - date;
    const days = timeGap / 1000 / 60 / 60 / 24;

    const [deferredPrompt, setCanInstall] = useState();
    window.addEventListener('beforeinstallprompt', (e) => {
        // Stash the event so it can be triggered later.
        e.preventDefault();
        setCanInstall(e);

        setTimeout(() => setCanInstall(null), 10000);

    });
    const classes = makeStyles(theme => ({
        root: {
            position: "fixed",
            top: 16,
            bottom: "unset",

            zIndex: 9999999,
            left: 0,
            right: 0,
            [theme.breakpoints.down('sm')]: {
                bottom: 0,
                top: "unset"
            }

        }
    }))();
    return (
        <Slide direction="down" in={deferredPrompt && days > 7 && false} mountOnEnter unmountOnExit>

            <div
                className={classes.root}>

                <Paper
                    square
                    elevation={8}
                    style={{
                        width: 480,
                        maxWidth: "100vw",
                        margin: "auto"
                    }}>
                    <div style={{
                        padding: "16px 16px",
                        display: "flex"
                    }}>
                        <img alt="logo" src={require("../../assets/img/ohsem.png")} width="32px" height="32px" />
                        <div style={{
                            flex: 1,
                            paddingTop: 4,
                            paddingLeft: 16
                        }}>

                            <Typography variant="subtitle1">Install app?</Typography>
                        </div>
                        <Button onClick={e => {
                            setCanInstall(false);
                            dispatch(setLastCancelInstallDate(new Date().getTime()))
                        }} >
                            No
                </Button>
                        <Button variant="contained" color="primary" onClick={e => {
                            deferredPrompt.prompt();
                            // Wait for the user to respond to the prompt
                            deferredPrompt.userChoice
                                .then((choiceResult) => {
                                    if (choiceResult.outcome === 'accepted') {
                                    } else {
                                    }
                                    setCanInstall(null);
                                });
                            setCanInstall(null);

                        }}>
                            Yes
                </Button>
                    </div>
                </Paper>

            </div>
        </Slide>);
}

export default connect(state => ({
    date: state.environment.lastCancelInstallDate
}))(InstallAppBottomSheet);