const environment = (state = {
  verticals: [],
  identities: [],
  countries:[],
  isConnected: false,

}, action) => {
  switch (action.type) {
    case 'UPDATE_VERTICAL':
      return {
        ...state,
        verticals: action.verticals,
        isConnected: true
      }

    case 'UPDATE_IDENTITY':
      return {
        ...state,
        identities: action.identities,
      }



    case 'UPDATE_COUNTRY':
      return {
        ...state,
        countries: action.countries,
      }


    default:
      return state
  }
  // return state

}

export default environment;