// eslint-disable-next-line
import storage from "localforage";
import React from "react";
import { Provider } from 'react-redux';
import Notification from "react-web-notification";
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import { createFilter } from 'redux-persist-transform-filter';
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import { PersistGate } from "redux-persist/integration/react";
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import thunk from 'redux-thunk';
import App from "./App";
import environment from './redux/environmentReducer';
import groupList from './redux/groupListReducer';
import loader from './redux/loaderReducer';
import propertiesDrawer from './redux/propertiesDrawerReducer';
import snackbar from './redux/snackbarReducer';
import staticLegend from './redux/staticLegendReducer';
import userCompany from './redux/userCompanyReducer';
import userProfile from './redux/userProfileReducer';
import user from './redux/userReducer';
import vertical from './redux/verticalReducer';
import workspace from './redux/workspaceReducer';
import workspaceV2 from './redux/workspaceV2Reducer';
import navigate from './redux/navigateReducer';
import drawer from './redux/drawerReducer';
import featureDataset from './redux/featureDatasetReducer';
import plan from './redux/planReducer';
import persona from './redux/personaReducer';
import rev from './redux/revReducer';


const encryptor = createEncryptor({
  secretKey: '{tn7q#BL~aM-9(Dh',
  onError: function (error) {

  }
})

const userAgent = navigator.userAgent.toLowerCase();
window.isRunOnElectron = userAgent.indexOf(' electron/') > -1 && window.require;

const appReducer = combineReducers(
  {
    groupList,
    snackbar,
    environment,
    staticLegend,
    propertiesDrawer,
    workspace,
    workspaceV2,
    loader,
    vertical,
    userProfile,
    user,
    userCompany,
    navigate,
    drawer,
    featureDataset,
    plan,
    persona,
    rev,

  }
);

const rootReducer = appReducer;

const saveSubsetFilter = createFilter(
  'workspace',
  ['tabs', 'tabCount'],
);
const saveSubsetFilter2 = createFilter(
  'workspaceV2',
  ['tabs', 'tabCount'],
);

const persistConfig = {
  key: 'vygr',
  storage,
  // stateReconciler:autoMergeLevel2,
  debug: (process.env.NODE_ENV === 'development'),
  transforms: [
    saveSubsetFilter,
    saveSubsetFilter2,
    encryptor,
  ],
  whitelist: ['workspace', 'workspaceV2', 'user', 'environment'] // navigation will not be persisted
}


const persistedReducer = persistReducer(persistConfig, rootReducer)


const composeEnhancers = composeWithDevTools({})

const middlewares = [
  thunk, createStateSyncMiddleware({
    blacklist: [
      'persist/PERSIST',
      'persist/REHYDRATE',
      "CHANGE_TAB_V2", 
      "SHOW_LOADER",
      "CLOSE_LOADER",
      "UPDATE_USER_PROFILE",
      "UPDATE_CRITERIA_INFO_V2",
      "UPDATE_TAB_V2",
      "REV_EDITOR_DATA_RESET",
      "REV_EDITOR_OPEN",
      "REV_EDITOR_CLOSE",
      "REV_EDITOR_DATA_CHANGE",
    ]

  }),
];

const enhancer = process.env.NODE_ENV === 'development' ? composeEnhancers(
  applyMiddleware(...middlewares),
  // other store enhancers if any
) : applyMiddleware(...middlewares);

export const store = createStore(persistedReducer, enhancer)
// const store = createStore(persistedReducer, applyMiddleware(thunk, ...middleware))
let persistor = persistStore(store)
initMessageListener(store);

const Main = () => <Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>

    <Notification title={""}></Notification>
    <App />
  </PersistGate>
</Provider>;

export default Main;