// import BaseApi from "./api/BaseApi.jsx"

import BaseApi from "./BaseApi";

class MaintenanceApi extends BaseApi {
    static path = "api/isMaintenance";


    static get() {
        const api = new BaseApi();
        api.call(api.https().get(`${MaintenanceApi.path}`));
        return api;
    }
  


}

export default MaintenanceApi;
