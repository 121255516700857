const defaultState = {
    admin: undefined,
    isUpdate: '',
    isDirty: [],
    isCreate: false,
    invalid: [],
    body: {
        IsPlanActive: true,
        // Id:'',
        // Email:'farid93liners@gmail.com',
        // FirstName:'Akhmad',
        // LastName:'Farid',
        // BirthDate:'1993-10-24',
        // Biography:'Atheletic ustadz',
        // PositionTitle:'Junior',
        // Department:'Engineering',
        // PhoneNumber:'+60146787293',
        // LandlineNumber:'+60898805511',
        // SpecializationId:'4',
        // GenderId:'2',
        // PositionLevelId:'3',
        // BackgroundRoleId:'13',
    }
}

const profile = (state = defaultState, action) => {
    switch (action.type) {
        case 'SIGN_OUT':
            return defaultState;
        case 'UPDATE_USER_PROFILE':
            return {
                ...state,
                ...action.profile,
                isUpdate: ''
            }
        case 'NEW_USER':
            return {
                ...state,
                ...action.newUser
            }
        case 'PROFILE_UPDATE':
            return {
                ...state,
                isUpdate: action.isUpdate
            }
        case 'CREATE':
            return {
                ...state,
                isCreate: action.isCreate
            }
        case 'ADMIN':
            return {
                ...state,
                admin: true
            }
        case 'NOT_ADMIN':
            return {
                ...state,
                admin: false
            }
        case 'INVALID':
            return {
                ...state,
                invalid: state.invalid.includes(action.invalid) ? state.invalid : [...state.invalid, action.invalid]
            }
        case 'VALID':
            return {
                ...state,
                invalid: [...state.invalid.filter(e => e !== action.invalid)]
            }
        case 'DIRTY':
            return {
                ...state,
                isDirty: state.isDirty.includes(action.isDirty) ? state.isDirty : [...state.isDirty, action.isDirty]
            }
        case 'CLEAN_DIRTY':
            return {
                ...state,
                isDirty: [...state.isDirty.filter(e => e !== action.isDirty)]
            }
        case 'PRISTINE':
            return {
                ...state,
                isDirty: []
            }
        case 'CLEAR_UPDATE':
            return {
                ...state,
                isUpdate: ''
            }
        case 'CLEAR_CREATE':
            return {
                ...state,
                isCreate: ''
            }
        default:
            return state
    }
}

export default profile;