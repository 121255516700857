import { updateChartUsage, updateCreditUsage } from "./plan"

export const signIn = ({ access_token: accessToken, refresh_token: refreshToken, Id, Redirect: redirect }) => ({
    type: 'SIGN_IN',
    accessToken,
    refreshToken,
    Id,
    redirect,
    expired: false
})

export const signInCopyToken = ({ accessToken, refreshToken, Id, redirect, origin }) => ({
    type: 'SIGN_IN',
    accessToken,
    refreshToken,
    Id,
    redirect,
    expired: false,
    origin
})

export const signOut = (expired) => function (dispatch) {
    dispatch({
        type: 'SIGN_OUT',
        expired
    })
    dispatch(updateChartUsage({}))
    dispatch(updateCreditUsage({}))
}

export const updateFacebookInfo = (facebookInfo) => ({
    type: 'UPDATE_FACEBOOK_INFO',
    facebookInfo
})

export const updateTikTokInfo = (tiktokInfo) => ({
    type: 'UPDATE_TIKTOK_INFO',
    tiktokInfo
})

export const updateRevInfo = (revInfo) => ({
    type: 'UPDATE_REV_INFO',
    revInfo
})


export const showSnackbar = (message, variant) => ({
    type: 'SHOW_SNACKBAR',
    message,
    variant,
    open: true
})

export const hideSnackbar = () => ({
    type: 'CLOSE_SNACKBAR',
    open: false
})


export const openDrawer = () => ({
    type: 'OPEN_DRAWER',
})

export const toggleDrawer = () => ({
    type: 'TOGGLE_DRAWER',
})

export const closeDrawer = () => ({
    type: 'CLOSE_DRAWER',
})


export const showLoader = (id) => ({
    type: 'SHOW_LOADER',
    open: true,
    id
})

export const hideLoader = (id) => ({
    type: 'CLOSE_LOADER',
    open: false,
    id
})

export const updateCurrency = (currencyId, currencySymbol) => ({
    type: 'UPDATE_CURRENCY',
    currencyId,
    currencySymbol
})

export const showPropertiesDrawer = (item, settings) => ({
    type: 'SHOW_PROPERTIES_DRAWER',
    item,
    settings,
    open: true
})

export const hidePropertiesDrawer = () => ({
    type: 'HIDE_PROPERTIES_DRAWER',
    open: false
})

export const showStaticLegend = (id, legend, color, excludeItems) => ({
    type: 'SHOW_STATIC_LEGEND',
    id,
    legend,
    color,
    open: true,
    excludeItems
})

export const hideStaticLegend = () => ({
    type: 'HIDE_STATIC_LEGEND',
    open: false
})


export const removeStaticLegend = (remove) => ({
    type: 'REMOVE_STATIC_LEGEND',
    remove: remove
})

export const includeStaticLegendItem = (index) => ({
    type: 'INCLUDE_STATIC_LEGEND_ITEM',
    index
})

export const excludeStaticLegendItem = (index) => ({
    type: 'EXCLUDE_STATIC_LEGEND_ITEM',
    index
})

export const highlightStaticLegend = (highlightIndex) => ({
    type: 'HIGHLIGHT_STATIC_LEGEND',
    highlightIndex
})

export const updateStaticLegendPosition = (top, right) => ({
    type: 'UPDATE_STATIC_LEGEND_POSITION',
    top,
    right
})

export const toggleStaticLegend = (expand) => ({
    type: 'TOGGLE_STATIC_LEGEND',
    expand
})


export const updateGroupList = groupList => ({
    type: "UPDATE_GROUP_LIST",
    groupList
})


export const changeTheme = theme => ({
    type: "CHANGE_THEME",
    theme
})

export const setLastCancelInstallDate = lastCancelInstallDate => ({
    type: "SET_LAST_CANCEL_INSTALL_DATE",
    lastCancelInstallDate
})

// Workspace : Start
export const generateWorkspace = (body) => ({
    type: "GENERATE_WORKSPACE",
    body
})

export const generateWorkspaceDone = (response, exception) => ({
    type: "GENERATE_WORKSPACE_DONE",
    response,
    exception
})
// Workspace : End

export const addTextLibrary = (key, text) => ({
    type: "ADD_TEXT_LIBRARY",
    key, text
})
