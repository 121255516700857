import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { primaryColor, secondaryColor, secondaryDarkColor } from "../jss/material-dashboard-react";


export const colorTones = {
    light: {
        primaryColor,
        secondaryColor,
        type: "light",
        borderColor: "#000000",
        backgroundColor: "#ffffff",
        defaultBackgroundColor: "#f5f9fe",
    },
    dark: {
        primaryColor,
        secondaryColor: secondaryDarkColor,
        type: "dark",
        borderColor: "#ffffff",
        backgroundColor: "#2b3036",
        defaultBackgroundColor: "#21252b",
    },
    night: {
        primaryColor,
        secondaryColor: secondaryDarkColor,
        type: "dark",
        borderColor: "#ffffff",
        backgroundColor: "#37474F",
        defaultBackgroundColor: "#263238",

    },
    // dattel: {
    //     type: "light",
    //     borderColor: "#000",
    //     backgroundColor: "#FFF",
    //     defaultBackgroundColor: "#F0F9F1",
    // },
    // dattelDark: {
    //     type: "dark",
    //     borderColor: "#ffffff",
    //     backgroundColor: "#060F07",
    //     defaultBackgroundColor: "#0B1E0E",
    // }
}


const createTheme = (theme = "light", primary) => {

    const {
        secondaryColor,
        backgroundColor,
        defaultBackgroundColor,
        type,
        borderColor
    } = colorTones[theme] ? colorTones[theme] : colorTones["light"];

    const isLight = theme === "light" || !theme;
    return createMuiTheme({
        typography: {

            fontFamily: ['Lato',
                "sans-serif"].join(", ")
        },
        palette: {
            type,
            // contrastThreshold: 2.6,
            contrastThreshold: 3,

            // primary: { main: primaryColor },
            primary: primary ?? { main: "#4285f4" },
            secondary: { main: "#4285f4" },
            background: {
                paper: backgroundColor,
                default: defaultBackgroundColor
            },

        },
        overrides: {
            MuiAppBar: {
            },
            Sidebar: {
                itemText: {

                }
            },
            MuiListItemIcon: {
                root: {

                }
            },
            MuiListItem: {
                button: {
                    "&:hover": {
                        backgroundColor: alpha(secondaryColor, 0.1)
                    }
                }
            },
            MuiListItemText: {
                root: {


                }
            },
            MuiSelect: {
                root: {
                    "& fieldset": {
                    }
                },
                icon: {

                }
            },
            MuiFab: {
                root: {
                    backgroundColor: backgroundColor,
                    color: type === "dark" && "white",
                    "&:hover": {
                        backgroundColor: backgroundColor,

                    }
                }
            },

            MuiSpeedDialAction: {
                button: {
                    backgroundColor: backgroundColor
                }
            },
            MuiButton: {
                root: {
                    textTransform: "inherit"
                },
                contained: {
                    color: borderColor,
                    backgroundColor: backgroundColor,
                    "&:hover": {
                        backgroundColor: defaultBackgroundColor,
                    }
                },
                label: {

                }
            },
            MuiIconButton: {
                root: {

                    width: 48,
                    height: 48,
                    padding: 0
                }
            },
            MuiTab: {
                textColorPrimary: {

                }
            },
            MuiMenuItem: {
                root: {

                }
            },
            MuiInput: {
                root: {

                }
            },
            MuiPaper: {

                elevation1: {
                    // boxShadow: "none",
                    // border: `1px solid ${alpha(borderColor, 0.2)}`
                },
                elevation2: {
                    // boxShadow: "none",
                    // border: `1px solid ${alpha(borderColor, 0.2)}`
                }
            },
            MuiFormLabel: {
                root: {
                    '&$focused': {
                        // color: "#70f182"
                    }
                }
            },
            MuiFilledInput: {
                inputMarginDense: {
                    paddingTop: 8,
                    paddingBottom: 8
                    // padding:"8px 12px 10px"
                },
            },
            MuiTypography: {
                root: {

                },

                body1: {

                }
            }, MuiDialogContentText: {
                root: {
                    // color: "#fff !important"
                }
            }, MuiModal: {
                root: {

                }
            }, MuiTableHeader: {
                root: {

                }
            }, MuiTableCell: {
                root: {
                    borderBottom: "1px solid rgba(205, 205, 205, 1)"
                },
                head: {

                }, body: {

                }
            }, MuiGridListTileBar: {
                title: {

                }
            }, MuiChip: {
                deleteIcon: {
                    color: "rgba(0, 0, 0, 0.26)"
                }
            }, MuiTooltip: {
                tooltip: {
                    backgroundColor: "#000"
                },
                arrow: {
                    color: "#000"
                }
            },
            MuiTablePagination: {
                select: {
                    paddingRight: 32
                }
            },
            MuiFormControlLabel: {
                label: {
                    color: isLight ? "black" : "white"
                }
            },
            MuiOutlinedInput: {
                input: "12px 14px"
            }
        }
    });
}

export default createTheme;