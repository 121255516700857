import { makeStyles } from '@material-ui/styles';
import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

function AlertDialog() {
  const [open, setOpen] = React.useState(true);


  function handleClose() {
    window.newVersionClose = true;
    setOpen(false);
  }

  const dialogTitleClasses = makeStyles(theme=>({
    root:{
        backgroundColor:theme.palette.primary.main, 
        "&>h2":{    color:"white !important"}
    }
  }))();
  return (
    <div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" classes={dialogTitleClasses}>New Release</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{paddingTop:16}}>
            Good News, version {window.latestVersion} has been released.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          Close
            
          </Button>
          <Button variant="contained" onClick={e=>{
            handleClose();
            if(window.require)
             window.require('electron').shell.openExternal("https://ctx.100doors.asia/download");
        }} color="primary" autoFocus>
          Get it!
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;