
let mainPath;

const setMainPath = path => {
    mainPath = path
}

let trialCode = "rev_trial";

const setTrialCode = path => {
    trialCode = path ?? "trial"
}

let voyagerUrl;

const setVoyagerUrl = path => {
    voyagerUrl = path?.endsWith("/") ? path.slice(0, path.length - 1) : path
}

let revUrl;

const setRevUrl = path => {
    revUrl = path?.endsWith("/") ? path.slice(0, path.length - 1) : path
}

let isLandingUseRevTheme;

const setIsLandingUseRevTheme = theme => {
    if (theme === "rev") //color config
        isLandingUseRevTheme = true
}
var CryptoJS = require("crypto-js");
const scadas = "`ACU7OvG0_C]wza"
const encryptToken = (token) => {
    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(token), scadas);
    return encrypted;
}
const decryptToken = (text) => {
    var decrypted = CryptoJS.AES.decrypt(text, scadas);

    try {
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
    } catch (e) {
        return undefined
    }
}
const getMobileOS = () => {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
        return "Android"
    }
    else if (/iPad|iPhone|iPod/.test(ua)
        || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return "iOS"
    }
    return "Other"
}
const isIframeSSOEnable = () => {

    if (!revUrl && !voyagerUrl) return true
    return !window.safari && getMobileOS() !== "iOS" && checkCookie()
}
function checkCookie() {
    var cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled) {
        document.cookie = "testcookie";
        cookieEnabled = document.cookie.indexOf("testcookie") !== -1;
    }
    return cookieEnabled;
}


export {
    isIframeSSOEnable,
    checkCookie,
    mainPath,
    setMainPath,
    isLandingUseRevTheme,
    setIsLandingUseRevTheme,
    trialCode,
    setTrialCode,
    voyagerUrl,
    setVoyagerUrl,
    encryptToken,
    decryptToken,
    revUrl,
    setRevUrl
}