const { default: CreditApi } = require("../api/CreditApi")
const { default: PaymentApi } = require("../api/Payment")

export function updateChartUsage(usage) {
    if (usage)
        return { usage, type: "UPDATE_CHART_USAGE" }

    return function (dispatch, getState) {
        PaymentApi.chartUsage().then({
            complete: (res, e) => {
                if (!e && res.data) {
                    dispatch(updateChartUsage(res.data))
                }
            }
        })
    }
}

export function updateCreditUsage(credit) {
    if (credit)
        return { credit, type: "UPDATE_CREDIT_USAGE" }

    return function (dispatch, getState) {
        CreditApi.chartUsage().then({
            complete: (res, e) => {
                if (!e && res.data) {
                    dispatch(updateCreditUsage({
                        ...res.data ?? {},
                        ChartBalance: res?.data?.TotalBalance
                    }))
                }
            }
        })

    }
}

