import BaseApi from "api/BaseApi";

class PaymentApi extends BaseApi {  
  static path = "api/Payment";
  static pathV2 = "api/v2/Payment";
  static pathV3 = "api/v3/Payment";

  static createBill(body) {
    const api = new BaseApi();

    api.call(api.https().post(`${PaymentApi.pathV2}/CreateBill`, body));

    return api;
  }
  static checkBill(id) {
    const api = new BaseApi();

    api.call(api.https().get(`${PaymentApi.pathV2}/CheckBill/${id}`));

    return api;
  }
  static revCheckBill(id) {
    const api = new BaseApi();

    api.call(api.https().get(`${PaymentApi.path}/MCILCheckBill/${id}`));

    return api;
  }
  static revCheckBillV2(id) {
    const api = new BaseApi();

    api.call(api.https().get(`${PaymentApi.pathV2}/MCILCheckBill/${id}`));

    return api;
  }
  static revCreateBill(body) {
    const api = new BaseApi();

    api.call(api.https().post(`${PaymentApi.path}/MCILCreateBill`, body));

    return api;
  }
  static revCreateBillV2(body) {
    const api = new BaseApi();

    api.call(api.https().post(`${PaymentApi.pathV2}/MCILCreateBill`, body));

    return api;
  }
  static revCheckPrice(body) {
    const api = new BaseApi();

    api.call(api.https().post(`${PaymentApi.path}/MCILCheckPrice`, body));

    return api;
  }
  static revCheckPriceV2(body) {
    const api = new BaseApi();

    api.call(api.https().post(`${PaymentApi.pathV3}/MCILCheckPrice`, body));

    return api;
  }
  static chartUsage() {
    const api = new BaseApi();

    api.call(api.https().get(`${PaymentApi.path}/ChartUsage`));

    return api;
  }
  static currentPlan() {
    const api = new BaseApi();

    api.call(api.https().get(`${PaymentApi.path}/CurrentPlan`));

    return api;
  }
  static receipt(body) {
    const api = new BaseApi();

    api.call(api.https().post(`${PaymentApi.pathV2}/Receipt`, body));

    return api;
  }
  static revReceipt(body) {
    const api = new BaseApi();

    api.call(api.https().post(`${PaymentApi.pathV2}/MCILReceipt`, body));

    return api;
  }
  static customerPortal(return_url) {
    const api = new BaseApi();

    api.call(api.https().post(`${PaymentApi.path}/CustomerPortal`, {return_url}));

    return api;
  }

}

export default PaymentApi;
