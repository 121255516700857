import axios from 'axios';
import { store } from "Main";
import { generateWorkspaceDone, signOut } from "../actions";
import { getCancelToken } from './config';

class BaseApi {
    requests = [];

    https() {
        return axios;
    }

    call(request) {
        this.requests = [request];
        return this;
    }

    then(callback, shouldSignOutOnUnauthorized = true) {

        this.request()
            .then(response => this.onThen(response, callback))
            .catch((e) => this.onCatch(e, callback, shouldSignOutOnUnauthorized));
    }

    execute() {
        const callback = {
            complete: (e, response) => {
                this.props.dispatch(generateWorkspaceDone(response, e));
            }
        };

        this.request()
            .then(response => this.onThen(response, callback))
            .catch((e) => this.onCatch(e, callback));
    }

    all(callback) {
        axios.all(this.requests)
            .then(response => this.onThen(response, callback))
            .catch((e) => this.onCatch(e, callback));
    }
    onThen(response, callback = {}) {
        const { success, fail, complete } = callback;

        if (response.data) {
            if (success)
                success(response);

        }
        else if (fail) {
            fail(response);
        }
        if (complete)
            complete(response);
    }
    onCatch(e, callback = {}, shouldSignOutOnUnauthorized = true) {
        const { error, complete } = callback;

        if (shouldSignOutOnUnauthorized && e.response && e.response.status === 401) {
            // signout({ history }, 0);
            store.dispatch(signOut(true));
        }
        if (error)
            error(e)
        if (complete)
            complete(null, e);
    }

    request() {
        return this.requests[0];
    }

    async requestAsync() {
        return this.requests[0];
    }



    append(api) {
        this.requests.push(api.request());
        return this;
    }


    cancel() {
        const cancelToken = getCancelToken();
        if (cancelToken)
            cancelToken.cancel("");
    }
}

export default BaseApi;
