const defaultState = {};
const environment = (state = defaultState, action) => {
  switch (action.type) {
    case 'SIGN_OUT':
      return defaultState;
   
    case 'REV_ACCOUNTS': {
      const { type, ...rest } = action;
      return {
        ...state,
        ...rest,
      };

    }
    case 'UPDATE_REV_CAMPAIGN_LIST': {
      const { type, accountId, ...rest } = action;
      const accountData = state[accountId] ?? {};

      return {
        ...state,
        [accountId]: {
          ...accountData,
          ...rest,
        }
      };
    }
    case 'UPDATE_REV_AUDIENCE_LIST': {

      const { type, accountId, ...rest } = action;
      const accountData = state[accountId] ?? {};

      return {
        ...state,
        [accountId]: {
          ...accountData,
          ...rest,
        }
      };
    }
    case 'UPDATE_REV_CREATIVE_LIST': {
      const { type, accountId, ...rest } = action;
      const accountData = state[accountId] ?? {};

      return {
        ...state,
        [accountId]: {
          ...accountData,
          ...rest,
        }
      };
    }
    case 'UPDATE_REV_AGENTS_LIST': {
      const { type, ...rest } = action;
      return {
        ...state,
        ...rest,
      };
    }
    case 'REV_EDITOR_DATA_RESET': {
      return {
        ...state,
        editor: {},
      };
    }
    case 'REV_EDITOR_CLOSE': {
      delete state.editor;
      return {
        ...state,
      };

    }
    case 'REV_EDITOR_OPEN': {
      const { type, ...rest } = action;
      return {
        ...state,
        ...rest,
        editor: {}
      };
    }
    case 'REV_EDITOR_DATA_CHANGE': {
      const { data } = action;
      return {
        ...state,
        editor: {
          ...state.editor,
          ...data
        }
      };
    }

    default:
      return state
  }
  // return state

}

export default environment;