import zoid from "zoid";

let ZoidSignOutComponent;
const createZoidSignOut = (url) => {
    ZoidSignOutComponent = zoid.create({
        tag: 'sign-out-component',
        url: `${url}/signout`,
        defaultContext: "popup",
        // autoResize: {
        //     width: true,
        //     height: true,
        // },
        dimensions: {
            width: "100%",
            height: "40px",
        },
    });
}
export { createZoidSignOut, ZoidSignOutComponent };

