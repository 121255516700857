import BaseApi from "api/BaseApi";

class CreditApi extends BaseApi {
  static path = "api/Credit";
  static pathV2 = "api/Credit";
  static pathV3 = "api/Credit";

  static createBill(body) {
    const api = new BaseApi();

    api.call(api.https().post(`${CreditApi.pathV2}/CreateBill`, body));

    return api;
  }
  static checkBill(id) {
    const api = new BaseApi();

    api.call(api.https().get(`${CreditApi.pathV2}/CheckBill/${id}`));

    return api;
  }
  static revCheckBill(id) {
    const api = new BaseApi();

    api.call(api.https().get(`${CreditApi.path}/MCILCheckBill/${id}`));

    return api;
  }
  static revCheckBillV2(id) {
    const api = new BaseApi();

    api.call(api.https().get(`${CreditApi.pathV2}/MCILCheckBill/${id}`));

    return api;
  }
  static revCreateBill(body) {
    const api = new BaseApi();

    api.call(api.https().post(`${CreditApi.path}/MCILCreateBill`, body));

    return api;
  }
  static revCreateBillV2(body) {
    const api = new BaseApi();

    api.call(api.https().post(`${CreditApi.pathV2}/CreditPayment`, body));

    return api;
  }
  static revCheckPrice(body) {
    const api = new BaseApi();

    api.call(api.https().post(`${CreditApi.path}/MCILCheckPrice`, body));

    return api;
  }
  static revCheckPriceV2(body) {
    const api = new BaseApi();

    api.call(api.https().post(`${CreditApi.pathV3}/CreditCheck`, body));

    return api;
  }
  static chartUsage() {
    const api = new BaseApi();

    api.call(api.https().get(`${CreditApi.path}/AccountSummary`));

    return api;
  }
  static currentPlan() {
    const api = new BaseApi();

    api.call(api.https().get(`${CreditApi.path}/CurrentPlan`));

    return api;
  }
  static receipt(body) {
    const api = new BaseApi();

    api.call(api.https().post(`${CreditApi.pathV2}/Receipt`, body));

    return api;
  }
  static revReceipt(body) {
    const api = new BaseApi();

    api.call(api.https().post(`${CreditApi.pathV2}/RevReceipt`, body));

    return api;
  }
  static transactionHistory(body) {
    const api = new BaseApi();
    api.call(api.https().post(`${CreditApi.path}/TransactionHistory`, body));

    return api;
  }

}

export default CreditApi;
