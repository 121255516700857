window.smoothScrollTo = function (element, to, duration) {

    if (element.scrollTop === to || to === undefined) {
        return;
    }
    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function () {
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}

if (!String.format) {
    String.format = function(format) {
      var args = Array.prototype.slice.call(arguments, 1);
      return format.replace(/{(\d+)}/g, function(match, number) { 
        return typeof args[number] != 'undefined'
          ? args[number] 
          : match
        ;
      });
    };
  }
window.smoothScrollXTo = function (element, to, duration) {
    var start = element.scrollLeft,
        change = to - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function () {
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollLeft = val;
        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}

const getVisibleHeight = (bounding) => {
    const height = (window.innerHeight || document.documentElement.clientHeight);

    let top = bounding.top;
    let bottom = bounding.bottom;
    if (bottom > height);
    bottom = height;
    if (top < 0)
        top = 0;
    return bottom - top;
}


const getHeight = (bounding) => {
    const height = (window.innerHeight || document.documentElement.clientHeight);

    let top = bounding.top;
    let bottom = bounding.bottom;
    const _height = bottom - top;
    return _height > height ? height : _height;
}
window.isVisible = (elem) => {
    // Get element's bounding
    var bounding = elem.getBoundingClientRect();

    // Check if it's out of the viewport on each side
    var out = {};
    out.top = bounding.top < 0;
    out.left = bounding.left < 0;
    out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight) * 1.1;
    out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
    out.any = out.top || out.left || out.bottom || out.right;
    out.all = out.top && out.left && out.bottom && out.right;


    return (!out.top && !out.bottom) || (out.top && out.bottom) ||
        ((out.top || out.bottom) && getVisibleHeight(bounding) >= getHeight(bounding) * 0.6)
    // (((out.top&&bounding.top<height*0.4)||(out.bottom&&bounding.top<height*0.6))&&(bounding.bottom-bounding.top)>=height*0.6);
}




var elem = document.documentElement;

/* View in fullscreen */
window.openFullscreen = () => {
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
    }
}

/* Close fullscreen */
window.closeFullscreen = () => {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
    }
}

window.toggleFulscreen = () => {
    if (document.fullscreenElement !== null) {
        window.closeFullscreen();
    } else {
        window.openFullscreen();

    }
}

window.latestVersion = "0.0.4"



//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};
