const environment = (state = {
  theme: localStorage.getItem("theme"),
  currencyId: localStorage.getItem("currencyId"),
  currencySymbol: localStorage.getItem("currencySymbol"),
  textLibs: {

  }
}, action) => {
  switch (action.type) {
    case 'CHANGE_THEME':
      localStorage.setItem("theme", action.theme)
      return {
        ...state,
        theme: action.theme
      }

    case 'OPEN_DRAWER':
      return {
        ...state,
        openDrawer: true
      }

    case 'CLOSE_DRAWER':
      return {
        ...state,
        openDrawer: false
      }

    case 'TOGGLE_DRAWER':
      return {
        ...state,
        openDrawer: !state.openDrawer
      }
    case 'SET_LAST_CANCEL_INSTALL_DATE':
      return {
        ...state,
        lastCancelInstallDate: action.lastCancelInstallDate
      }

    case 'ADD_TEXT_LIBRARY':
      const { key, text } = action;
      return {
        ...state,
        textLibs: {
          ...state.textLibs,
          [key]: text
        }

      }

    case "UPDATE_CURRENCY":
      const { currencyId, currencySymbol } = action;
      localStorage.setItem("currencyId", currencyId)
      localStorage.setItem("currencySymbol", currencySymbol)

      return {
        ...state,
        currencyId,
        currencySymbol,
      }


    default:
      return state
  }
  // return state

}

export default environment;