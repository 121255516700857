
const defaultState = {
  pinned: true,
  open: false, expand: true, excludeItems: {},
  remove: false
  // top: (localStorage.getItem("staticLegendPostion-top")||0),
  // right: (localStorage.getItem("staticLegendPostion-right")||0)
}
const snackbar = (state = defaultState, action) => {
  switch (action.type) {
    case 'SHOW_STATIC_LEGEND':
      return {
        ...state,
        open: true,
        id: action.id,
        legend: action.legend,
        color: action.color,
        excludeItems: action.excludeItems
      }

    case 'HIDE_STATIC_LEGEND':
      return {
        ...state,
        open: false,
        legend: []

      }


    case 'REMOVE_STATIC_LEGEND':
      return {
        ...state,
        highlightIndex:-1,
        remove: action.remove,
      }

    case 'INCLUDE_STATIC_LEGEND_ITEM':
      delete state.excludeItems[action.index];
      return {
        ...state,
        includeItemIndex: action.index,
        excludeItemIndex: -1

      }

    case 'EXCLUDE_STATIC_LEGEND_ITEM':
      state.excludeItems[action.index] = true;
      return {
        ...state,
        excludeItemIndex: action.index,
        includeItemIndex: -1

      }

    case 'HIGHLIGHT_STATIC_LEGEND':
      return {
        ...state,
        highlightIndex: action.highlightIndex

      }
    case 'UPDATE_STATIC_LEGEND_POSITION':
      localStorage.setItem("staticLegendPostion-top", action.top);
      localStorage.setItem("staticLegendPostion-right", action.right);
      return {
        ...state,
        top: action.top,
        right: action.right,

      }
    case 'TOGGLE_STATIC_LEGEND':
      return {
        ...state,
        expand: action.expand

      }


    default:
      return state
  }
  // return state

}

export default snackbar;