import React from "react";
import { Redirect } from "react-router-dom";
import { redirectQuery } from "./utils";


const CustomRedirect = ({ component: Component, ...rest }) => (
  <Redirect
    {...rest}
    render={props => {


      const redirect = redirectQuery(props);
      if (redirect)
        return redirect;

      return <Component {...props} />

    }
    }
  />
);

export default CustomRedirect;