import BaseApi from "api/BaseApi";

class UserProfileApi extends BaseApi {
    static path = "api/userprofile/";
    static users = "api/users/";
    static user = "api/user/";

    static get(id) {
        const api = new BaseApi();

        api.call(api.https().get(`${UserProfileApi.user}${id}`));

        return api;
    }

    static create(body) {
        const api = new BaseApi();

        api.call(api.https().post(`${UserProfileApi.user}`, body));

        return api;
    }
    

    static update(id, body) {
        const api = new BaseApi();

        api.call(api.https().put(`${UserProfileApi.user}${id}`, body));

        return api;
    }

    static delete(id) {
        const api = new BaseApi();

        api.call(api.https().delete(`${UserProfileApi.path}${id}`));

        return api;
    }

    static changepassword(body) {
        const api = new BaseApi();

        api.call(api.https().post(`${UserProfileApi.user}changePassword`, body));

        return api;
    }

    static getUsers(companyId, offset, limit) {
        const api = new BaseApi();

        api.call(api.https().get(`${UserProfileApi.users}${companyId}?offset=${offset}&limit=${limit}`));

        return api;
    }

    static checkEmail(email) {
        const api = new BaseApi();

        api.call(api.https().get(`api/checkEmail`, {
            params: {
                email: `${email}`
            }
        }));

        return api;
    }
    static getViewingLists() {
        const api = new BaseApi();
    
        api.call(api.https().get(`api/viewingListsSelfRegister`));
    
        return api;
      }
}

export default UserProfileApi;
