function addTab(state, { type, ...rest }, index) {
  const tab = {
    reports: [],
    createdDate: new Date().getTime(),
    ...rest,
  };

  state.tabs[index] = (tab)
  return tab;
}
const environment = (state = (() => {
  const tabs = {
    0: {
      title: "Tab 1",
      reports: [],
    }
  }
  return {
    tabCount: 1,
    create: tabs[0].reports,
    reports: [],
    model: {},
    publicReports: [],
    tabs,
    Verticals: [],
    focusTab: 0,
    labelLibrary: {},
    RowHeadersMap: {},
    ColumnHeadersMap: {},
  }
})(), action) => {


  switch (action.type) {
    case 'STORE_MODEL':
      return {
        ...state,
        model: action.model,
      }
    case 'GENERATE_WORKSPACE': {
      const focusTab = state.focusTab || 0;
      const reports = state.tabs[focusTab].reports || [];
      let newState = action.model ? [
        ...action.model,
        ...reports.filter(c => c.referId !== action.model[0].replaceId)
      ] : [
          ...reports
        ];
      state.tabs[focusTab].reports = newState;
      state.tabs[focusTab].reportCount = newState.length;


      return {
        ...state,
        tabs: { ...state.tabs },
        create: newState,
      }
    }
    case 'UPDATE_WORKSPACE': {
      let { tabs, focusTab } = state;
      const { id, title } = action;

      const report = tabs[focusTab].reports.find(report => report.id === id);

      if (!report)
        return state;

      report.Title = title;
      // tabs[focusTab].reports = create;
      return {
        ...state,
        focusTab
      }
    }
    case "REMOVE_WORKSPACE":
      const { referId } = action.model;
      return {
        ...state,
        create: state.create.filter(c => c.referId !== referId),
      }
    case 'ADD_TAB':

      let _index = 0;
      while (state.tabs[_index]) {
        _index++;
      }
      if (!action.title)
        action.title = `Tab ${Math.abs(_index + 1)}`;

      const tab = addTab(state, action, _index);
      return {
        ...state,
        focusTab: _index,
        tabs: state.tabs,
        create: tab.reports,
      }
    case 'CHANGE_TAB':
      if (!state.tabs[action.tabIndex]) {
        action.title = `${action.tabIndex < 0 ? ("Report " + Math.abs(action.tabIndex)) : ("Criteria " + (action.tabIndex + 1))}`;
        addTab(state, action, action.tabIndex);
      }
      return {
        ...state,
        focusTab: action.tabIndex,
        create: state.tabs[action.tabIndex].reports,
      }

    case 'UPDATE_TAB':

      let { tabIndex } = action;
      const { focusTab, tabs } = state;

      if (!tabIndex) {

        if (focusTab >= 0) {
          tabIndex = focusTab;
        } else {
          const keys = Object.keys(tabs).sort((key1, key2) => {
            const a = tabs[key1];
            const b = tabs[key2];
            return a && b ? (a.createdDate - b.createdDate) : 0;
          })
            .filter(key => key >= 0);
          if (keys.length) {
            tabIndex = keys[0];
          } else {
            tabIndex = 0;
          }
        }
      }

      if (!state.tabs[tabIndex]) {
        state.tabs[tabIndex] = addTab(state, action, tabIndex);
      }
      state.tabs[tabIndex] = {
        ...state.tabs[tabIndex],
        ...action.props,
      }
      return {
        ...state,
      }

    case 'DELETE_TAB': {
      let { tabs } = state;

      const keys = Object.keys(tabs).sort((key1, key2) => {
        const a = tabs[key1];
        const b = tabs[key2];
        return a && b ? (a.createdDate - b.createdDate) : 0;
      })
        .filter(key => key >= 0);
      _index = keys.indexOf(action.tabIndex);
      (delete tabs[action.tabIndex]);

      _index = keys[_index > 0 ? _index - 1 : _index + 1];


      if (isNaN(_index) || _index < 0) {
        _index = 0;
        addTab(state, {
          title: "Tab 1",
        }, 0);
      }

      const _newState = {
        ...state,
        focusTab: _index,
        create: tabs[_index].reports,
        tabs: { ...tabs },

      };
      return _newState
    }
    case 'ADD_ALL_REPORTS': {
      // tabs
      if (action.reports) {
        const tab = addTab(state, action, -action.reports[0].ReportId);
        tab.reports = action.reports;
        tab.title = "Report " + action.reports[0].ReportId;

        return {
          ...state,
          focusTab: _index,
          tabs: state.tabs,
          create: tab.reports,

          reports: [
            // ...state.reports,
            // ...action.model
          ],
        }
      }
      else
        return state;
    }
    case 'DELETE_REPORT':

      const newState = [
        ...state.create.filter(c => c.id !== action.id)
      ]
      state.tabs[state.focusTab].reports = newState;
      state.tabs[state.focusTab].reportCount = newState.length;
      return {
        ...state,
        tabs: { ...state.tabs },
        create: newState,
      }
    case 'DELETE_ALL_REPORTS':
      return {
        ...state,
        reports: []
      }
    case 'DELETE_REPORTS':
      const index = state.reports.indexOf(action.report);

      if (index >= 0)
        state.reports.splice(index, 1);
      return {
        ...state,
        reports: [
          ...state.reports
        ]
      }

    case 'CLEAR_CREATE_WORKSPACE':
      state.tabs[state.focusTab].reports = [];
      return {
        ...state,
        create: state.tabs[state.focusTab].reports,
      }


    case 'UPDATE_CRITERIA_INFO':
      const {
        Verticals,
        RowHeaders, ColumnHeaders,
        noOfColumn, noOfRow,
        labelLibrary, headerByKey,
        query
      } = action;
      if (!state.RowHeadersMap) {
        state.RowHeadersMap = {};
      }
      if (!state.ColumnHeadersMap) {
        state.ColumnHeadersMap = {};
      }
      state.RowHeadersMap[query] = RowHeaders;
      state.ColumnHeadersMap[query] = ColumnHeaders;
      return {
        ...state,
        Verticals,
        RowHeaders,
        ColumnHeaders,
        noOfColumn,
        noOfRow,
        labelLibrary,
        headerByKey

      }
    case "STORE_PUBLIC_REPORTS":
      const { publicReports } = action;

      return {
        ...state,
        publicReports
      }

    default:
      return state;
  }
  // return state

}

export default environment;