import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { redirectQuery } from "./utils";
import Helmet from "react-helmet";
import { withLocalize } from "react-localize-redux";
import { isLandingUseRevTheme, mainPath } from "utils/config";

const PublicRoute = ({ component: Component, accessToken, redirect: redirectUrl, translate, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {



      const redirect = redirectQuery(props);
      if (redirect)
        return redirect;

      const auth = Boolean(accessToken);
      const isValid = rest.path.startsWith("error")
        || window.location.pathname.includes("/subscribe")
        || !auth
        || (!window.location.pathname.includes("/signin") && window.location.pathname !== ("/"));


      

      return isValid ? (
        <React.Fragment>
          <Helmet>
            {rest.title ? <title>{translate(rest.title)}</title> : <title>{isLandingUseRevTheme ? "MCIL" : "OHSEM Ads"}</title>}
            {rest.description ? <meta name="description" content={rest.description} /> : ""}
          </Helmet>
          <Component {...props} />
        </React.Fragment>
      ) : (
        <Redirect
          to={{
            pathname: mainPath ?? redirectUrl ?? "/u",
            state: { from: props.location }
          }}
        />
      )
    }
    }
  />
);

export default connect((state) => (state.user))(withLocalize(PublicRoute));
