const defaultState = {

}
const persona = (state = (() => {
  return defaultState;
})(), action) => {


  switch (action.type) {
    case 'SIGN_OUT':
      return defaultState;
    case "UPDATE_PERSONA_LIST": {
      const { personas } = action;
      return {
        ...state,
        personas
      }
    }


    default:
      return state;
  }
  // return state

}

export default persona;